import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import img_elImage2 from './images/StartScreen_elImage2_494004.jpg';
import UnlimitedNow from './UnlimitedNow';

export default class StartScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
    {
      let dataSheet = this.context.appActions.dataSheets['sheet1'];
      let serviceOptions = this.context.appActions.serviceOptions_sheet1;
      if ( !this.context.appActions.dataSheetLoaded['sheet1']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("", this.context.appActions.dataSlots);
        this.context.appActions.loadData_jsonsrc1(dataSheet, serviceOptions, true);
        this.context.appActions.dataSheetLoaded['sheet1'] = true;
      }
      this._dataSheetLoadTimer_sheet1 = setInterval(() => {  // Reload data regularly
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("", this.context.appActions.dataSlots);
        this.context.appActions.loadData_jsonsrc1(dataSheet, serviceOptions, false);
      }, 10000);
    }
  }

  componentWillUnmount() {
    clearInterval(this._dataSheetLoadTimer_sheet1);
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    // eslint-disable-next-line
    const dataSheet_sheet1 = this.context.dataSheets['sheet1'];
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#f6f6f6',
     };
    const style_elImage2 = {
      backgroundImage: 'url('+img_elImage2+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    items_list = items_list.concat(this.context.appActions.getDataSheet('sheet1').items);
    this._elList_items = [];
    
    const style_elList = {
      height: 'auto',  // This element is in scroll flow
     };
    
    return (
      <div className="AppScreen StartScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elImage2">
            <div style={style_elImage2} />
          </div>
          
          <div className="hasNestedComps elList">
            <ul style={style_elList}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <UnlimitedNow dataSheetId={'sheet1'} dataSheetRow={row} {...{ 'Title': row['Title'], 'Alias': row['Alias'], }} ref={(el) => {if (el) this._elList_items.push(el)}} />;
                return (<li key={row.key || index}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._elList_endMarker = el} />
            </ul>
          </div>
        </div>
        
      </div>
    )
  }
  
}
